import AccessManagement from "pages/Management/AccessManagement";
import Account from "pages/Account/Account";
import AppsPage from "pages/Apps";
import Devices from "pages/Devices";
import ForgotPassword from "pages/ForgotPassword";
import IPTablePage from "pages/IPTablePage";
import Login from "pages/Login";
import MultiDashboardPage from "pages/MultiDashboard";
import Networks from "pages/Networks";
import RemoteConsolePage from "pages/RemoteConsole";
import ResetPassword from "pages/ResetPassword";
import SignUp from "pages/Signup";
import SingleDashboardPage from "pages/SingleDashboard";
import SingleNetworkPage from "pages/SingleNetworkPage";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { authActions } from "../../redux/slice/auth.slice";
import PrivateLayoutRoutes from "./PrivateLayoutRoutes";
import PrivateNoLayoutRoutes from "./PrivateNoLayoutRoutes";
import OrganizationManagement from "pages/Management/OrganizationManagement";
import BlockchainPage from "pages/BlockchainPage";
import PricingPlansPage from "pages/PricingPlansPage";

const MyRouter = () => {
  const auth = useSelector(authActions.selectors.getLoggedInStatus);

  return (
    <Router>
      <Routes>
        <Route element={<PrivateLayoutRoutes auth={auth} />}>
          <Route path="/devices" element={<Devices />} />
          <Route path="/apps" element={<AppsPage />} />
          <Route path="/single-dashboard/:id" element={<SingleDashboardPage />} />
          <Route path="/ip-table/:id" element={<IPTablePage />} />
          <Route path="/" element={<MultiDashboardPage />} />
          <Route path="/account" element={<Account />} />
          <Route path="/portal-access-management" element={<AccessManagement />} />
          <Route path="/organization-access-management" element={<OrganizationManagement />} />
          <Route path="/networks" element={<Networks />} />
          <Route path="/single-network/:id" element={<SingleNetworkPage />} />
          <Route path="/blockchain/tx/:txnId" element={<BlockchainPage />} />

        </Route>
        <Route element={<PrivateNoLayoutRoutes auth={auth} />}>
          <Route path="/pricing" element={<PricingPlansPage />} />
          <Route path="/console" element={<RemoteConsolePage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
};

export default MyRouter;
