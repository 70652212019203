import BlockChain from 'components/modules/BlockChain/BlockChain'
import React from 'react'

const BlockchainPage = () => {
    return (
        <div>
            <BlockChain />
        </div>
    )
}

export default BlockchainPage
