import { createSelector, createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';

const initialState = {
  groups: {
    "Functional": [],
    "Location-Based": [],
    "Criticality-Based": [],
    "Vendor/Product": [],
    "Regulatory Compliance": [],
    "Custom": [],
  },
  status: 'idle',
  error: null,
  accessPrevilage: {},
  users: [],
  invites: []
};

const parentSelector = state => state?.["group"]

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    fetchGroupsRequest: (state) => {
      state.status = 'loading';
    },
    fetchGroupsSuccess: (state, action) => {
      state.status = 'succeeded';
      state.groups = action.payload;
    },
    fetchGroupsFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    createGroupRequest: (state) => {
      state.status = 'loading';
    },
    createGroupSuccess: (state, action) => {
      state.status = 'succeeded';
      toast.success('Group Created')
      state.groups[action.payload.type].push(action.payload);
    },
    createGroupFailure: (state, action) => {
      state.status = 'failed';
      toast.error('Something went wrong')
      state.error = action.payload;
    },
    addAppToGroupRequest: (state) => {
      state.status = 'loading';
    },
    addAppToGroupSuccess: (state, action) => {
      state.status = 'succeeded';
      toast.success('Application added to group successfully');
      // You may need to update the state here if necessary
    },
    addAppToGroupFailure: (state, action) => {
      state.status = 'failed';
      toast.error(action.payload);
      state.error = action.payload;
    },
    setAccessPrevilage: (state, action) => {
      return {
        ...state,
        accessPrevilage: action.payload,
      };
    },
    setUsers: (state, action) => {
      return {
        ...state,
        users: action.payload,
      };
    },
    setInvites: (state, action) => {
      return {
        ...state,
        invites: action.payload,
      };
    },

  },
});

const getAccessPrevilage = createSelector(parentSelector, state => {
  return state?.accessPrevilage
})

const getUsers = createSelector(parentSelector, state => {
  return state?.users
})

const getInvites = createSelector(parentSelector, state => {
  return state?.invites
})

export const groupActions = groupSlice.actions;

export const groupSelectors = {
  getAccessPrevilage,
  getUsers,
  getInvites
}

export default groupSlice;
