import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    blockChain: [],
    blockChainBlock: {},
}

const name = 'blockChain'
const parentSelector = state => state?.[name]

const blockChainSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetBlockChain: () => {
            return initialState;
        },
        setBlockChain: (state, action) => {
            return {
                ...state,
                blockChain: action.payload,
            };
        },
        setBlockChainBlock: (state, action) => {
            return {
                ...state,
                blockChainBlock: action.payload,
            };
        },
    },
});


const getBlockChain = createSelector(parentSelector, (state) => {
    return state?.blockChain;
});

const getBlockChainBlock = createSelector(parentSelector, (state) => {
    return state?.blockChainBlock;
});


const actions = {
    ...blockChainSlice.actions
}

const selectors = {
    getBlockChain,
    getBlockChainBlock
}

export const blockChainActions = { actions, selectors }


export default blockChainSlice;