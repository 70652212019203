import PricingPlan from 'components/modules/PricingPlan/PricingPlan'
import React from 'react'

const PricingPlansPage = () => {
    return (
        <>
            <PricingPlan />
        </>
    )
}

export default PricingPlansPage
