import { Badge, Button } from "@mui/material";
import dayjs from "dayjs";
import { openRemoteConsole } from "../../modules/SingleDashboard/helper";
import { DeviceStatus } from "../../utils/enums";
import theme from "../../utils/theme";
import tables from "./table.module.scss";
import { devicesActions } from "../../../redux/slice/devices.slice";
import toast from "react-hot-toast";
import { SEND_TWO_FA_OTP } from "../../../redux/types/auth.types";
import { formatDateBdata } from "components/utils";
import { handleOpenBlockHash } from "components/modules/Networks/NetworkKeyFeatures/MapSummary/helper";

export const getBadge = (name) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case DeviceStatus.connected:
      return <Badge badgeContent={name} color="success" />;
    case DeviceStatus.disconnected:
      return <Badge badgeContent={name} color="error" />;
    case DeviceStatus.registered:
    case DeviceStatus.activated:
      return (
        <Badge
          badgeContent={name}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.warning.light,
            },
          }}
        />
      );
    case DeviceStatus.add_credentials:
      return (
        <Badge
          badgeContent={name}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white.main,
              width: "100px"
            },
          }}
        />
      );
    case DeviceStatus.verifying:
      return (
        <Badge
          badgeContent={DeviceStatus.verifying}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.white.main,
              // width: "100px"
            },
          }}
        />
      );
    case DeviceStatus.verified:
      return (
        <Badge
          badgeContent={DeviceStatus.verified}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.info.main,
              color: theme.palette.white.main,
            },
          }}
        />
      );
    case DeviceStatus.partialConnected:
      return (
        <Badge
          badgeContent={DeviceStatus.partialConnected}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.warning.main,
              width: "120px"
            },
          }}
        />
      );
    case DeviceStatus.reScanning:
      return (
        <Badge
          badgeContent={DeviceStatus.reScanning}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.info.main,
              width: "100px",
              color: theme.palette.background.paper
            },
          }}
        />
      );
  }
};



export const getCredentialsBadge = (name) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case true:
      return <Badge badgeContent={"Verified"} color="success" />;
    case false:
      return <Badge badgeContent={"Not Verified"} color="error" />;
  }
};

export const getStatusBadge = (name) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case "LISTENING":
      return <Badge badgeContent={"Connected"} color="success" />;
    case "CLOSE_WAIT":
      return <Badge badgeContent={"Disconnected"} color="error" />;
    case "ESTABLISHED":
      return (
        <Badge
          badgeContent={"Active"}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.warning.light,
            },
          }}
        />
      );
    case "TIME_WAIT":
      return (
        <Badge
          badgeContent={"Pending"}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.warning.light,
            },
          }}
        />
      );
  }
};

export const getAlertBadge = (alert) => {
  switch (alert) {
    case "low":
      return <Badge badgeContent={"Low"} color="success" />;
    case "high":
      return <Badge badgeContent={"High"} color="error" />;
    case "medium":
      return (
        <Badge
          badgeContent={"Medium"}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.warning.light,
            },
          }}
        />
      );
  }
};

export const customTableStyles = {
  border: 0,
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: theme.palette.gray.bg,
  },
  "& .MuiDataGrid-columnHeaders": {
    padding: "10px",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: theme.palette.white.main,
  },
  "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "& .FunctionalGroups_container__v6GcH": {
    border: "solid 1px red",
    marginBottom: "20px",
  },
  "& .css-1essi2g-MuiDataGrid-columnHeaderRow": {
    borderRadius: "20px",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    fontWeight: "bolder",
  },
  '& .MuiDataGrid-cell[data-field="status"]': {
    paddingLeft: "50px",
  },
  '& .MuiDataGrid-cell[data-field="alertLevel"]': {
    paddingLeft: "65px",
  },
  '& .MuiDataGrid-cell[data-field="protocol"]': {
    paddingLeft: "50px",
  },
  '& .MuiDataGrid-cell[data-field="args"]': {
    paddingLeft: "40px",
  },
  '& .MuiDataGrid-cell[data-field="active"]': {
    paddingLeft: "50px",
  },
  '& .MuiDataGrid-cell[data-field="size"]': {
    paddingLeft: "50px",
  },
  "& .MuiDataGrid-cellCheckbox": {
    paddingLeft: "18px",
  },

  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    cursor: "pointer",
    paddingLeft: "20px",
  },
  '& .MuiDataGrid-cell--editing': {
    boxShadow: "none !important",
    backgroundColor: "transparent !important"
  },
  "& .MuiDataGrid-virtualScroller": {
    "&::-webkit-scrollbar": {
      width: "2px",
      height: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.white.dark,
    },
  },
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.white.dark}`,
};

export const multiLineCellStyles = {
  "& .MuiDataGrid-cell": {
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: "20px",
  },
};

export const drawerPaperStyles = {
  ".MuiDrawer-paper": {
    width: "400px",
    position: "absolute",
    bottom: 0,
    height: "492px",
    marginTop: "65px",
  },
};

export const getDeviceColumns = (
  handleInstallDevice,
  dispatch,
  isTwoFaEnabled,
  setIs2FAOpen,
  user,
) => [
    {
      field: "deviceId",
      headerName: "ID",
      width: 150,

    },
    {
      field: "deviceName",
      headerName: "Device Name",
      width: 170,
      editable: true
    },
    {
      field: "assetId",
      headerName: "Asset ID",
      width: 100,

    },
    {
      field: "systemDetails.ip",
      headerName: "IP",
      width: 130,

      renderCell: (params) => {
        const ip = params?.row?.systemDetails?.ip;
        const maskedIp = user.role === 'admin' || user?.isTwoFaVerified || !user?.isTwoFaEnabled ? ip : ip?.replace(/\d{1,3}$/, 'xx');
        return <p>{maskedIp}</p>;
      },
    },
    {
      field: "os",
      headerName: "OS",
      width: 100,

      renderCell: (params) => <p>{params?.row?.systemDetails?.os}</p>,
    },
    {
      field: "lastAuthenticated",
      headerName: "Last Authenticated",
      width: 180,
      renderCell: (params) => formatDateBdata(params?.row?.lastAuthenticated)
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,

      renderCell: (params) => getBadge(params?.row?.status),
    },
    {
      field: "lastActive",
      headerName: "Last Active",
      width: 180,
      renderCell: (params) => formatDateBdata(params?.row?.lastActive)

    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,

      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={(event) => {
            handleActionClick(params?.row, handleInstallDevice);
            event.stopPropagation();
          }}
          className={tables.actionBtn}
          size="small"
        >
          {params.row.status === DeviceStatus.registered ? "Install" : "Txn"}
        </Button>
      ),
    },
    {
      field: "remoteAccess",
      headerName: "Remote Access",
      width: 150,

      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={async (event) => {
            if (params?.row?.status !== DeviceStatus.connected) {
              toast.error("Cannot open remote console: Device is disconnected.");
              event.stopPropagation();
              return;
            }
            dispatch(devicesActions.actions.setSelectedDevice(params?.row));
            if (!isTwoFaEnabled || user?.role === 'admin' || user?.isTwoFaVerified) {
              openRemoteConsole(params?.row?._id);
            } else {
              const paylaod = { user }
              dispatch({ type: SEND_TWO_FA_OTP, paylaod })
              setIs2FAOpen(true);
            }
            event.stopPropagation();
          }}
          className={tables.actionBtn}
          size="small"
        >
          Console
        </Button>
      ),
    },
  ];

export const handleActionClick = (row, callBack1) => {
  if (row?.status === DeviceStatus.registered) {
    callBack1(row?.token);
  } else {
    handleOpenBlockHash(row?.assetIdTxn)
  }
};

export const handleDeviceDialogue = () => { };
