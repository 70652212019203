import React from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LayersIcon from "@mui/icons-material/Layers";
import NumbersIcon from "@mui/icons-material/Numbers";
import styles from "./BlockInfo.module.scss";
import { formatDateBdata } from "components/utils";

const BlockInfo = ({ block }) => {
    return (
        <div className={styles.container}>
            {/* Transaction Hash */}
            <div className={styles.row}>
                <FingerprintIcon className={styles.icon} />
                <div>
                    <span className={styles.label}>Transaction Hash:</span>
                    <span className={styles.value}>
                        {block?.hash ? block.hash : "N/A"}
                    </span>
                </div>
            </div>

            {/* Status */}
            <div className={styles.row}>
                <CheckCircleIcon className={styles.icon} />
                <div>
                    <span className={styles.label}>Status:</span>
                    <span className={styles.value}>{block?.status || "Success"}</span>
                </div>
            </div>

            {/* Timestamp */}
            <div className={styles.row}>
                <AccessTimeIcon className={styles.icon} />
                <div>
                    <span className={styles.label}>TimeStamp:</span>
                    <span className={styles.value}>
                        {formatDateBdata(block?.timestamp) || "Not Available"}
                    </span>
                </div>
            </div>

            {/* Block */}
            <div className={styles.row}>
                <LayersIcon className={styles.icon} />
                <div>
                    <span className={styles.label}>Block:</span>
                    <span className={styles.value}>{block?.index || "N/A"}</span>
                </div>
            </div>

            {/* Nounce */}
            <div className={styles.row}>
                <NumbersIcon className={styles.icon} />
                <div>
                    <span className={styles.label}>Nonce: </span>
                    <span className={styles.value}>{block?.nonce || "N/A"}</span>
                </div>
            </div>
        </div>
    );
};

export default BlockInfo;
