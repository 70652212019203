import ProfileMenu from 'components/shared/navbar/ProfileMenu/ProfileMenu';
import { useSelector } from 'react-redux';
import LogoIcon from 'static/svgs/logo';
import { authActions } from '../../../redux/slice/auth.slice';
import PricingBody from './PricingBody';
import styles from './pricingPlan.module.scss';
const PricingPlan = () => {
    const currentUser = useSelector(authActions.selectors.getCurrentUser)

    return (
        <div className={styles.pricingContainer}>
            <div className={styles.pricingNavContainer}>
                <LogoIcon />
                <ProfileMenu user={currentUser} showDropDown={false} />
            </div>
            <div className={styles.pricingBodyContainer}>
                <PricingBody />
            </div>
        </div>
    )
}

export default PricingPlan
