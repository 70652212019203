import React, { useEffect } from "react";
import text from "components/shared/css/text.module.scss";
import styles from "./signup.module.scss";
import LogoIcon from "static/svgs/logo";
import LoginSlider from "../Login/LoginSlider";
import SignUpForm from "./SignUpForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authActions } from "../../../redux/slice/auth.slice";

const SignUpContainer = () => {

  const navigate = useNavigate();
  const isSignedUp = useSelector(authActions.selectors.getSignUpSuccess);

  useEffect(() => {
    if (isSignedUp) {
      navigate('/login');
    }
  }, [isSignedUp, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <LogoIcon />
        <div className={styles.loginBox}>
          <SignUpForm />
        </div>
      </div>
      <div className={styles.right}>
        <div>
          <LoginSlider />
        </div>
        <p className={`${text.normal2} ${text.textWhite} ${text.textCenter}`}>
          © 2025 BDATA. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default SignUpContainer;
