import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";
import CryptoJS from "crypto-js";

export const YupSignUpSchema = {
  email: string().email(),
  username: string().required(),
  password: string().required(),
};

export const formSignUpSchema = (signupSchema) => {
  return {
    resolver: yupResolver(signupSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      username: "",
    },
  };
};


export const decryptValue = (encryptedValue, key) => {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};