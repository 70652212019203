import NetworkKeyFeatures from "components/modules/Networks/NetworkKeyFeatures/NetworkKeyFeatures";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "../redux/slice/ui.slice";

const Networks = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(uiActions.actions.setSelectedNav("Networks"));
  }, [dispatch])

  return (
    <NetworkKeyFeatures />
  );
};

export default Networks;
