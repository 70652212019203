import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import theme from "components/utils/theme";

export const AntSwitch = styled(Switch)(() => ({
    width: 30,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(14px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: '#fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


export const pricingPlans = [
    {
        title: "Basic",
        monthlyPrice: "75",
        yearlyPrice: "60",
        description: "Ideal for small teams with 3 users and 5 devices.",
        features: [
            "30 days Free Trial",
            "No. of Users : 3",
            "Devices Enrollment : 5",
        ],
    },
    {
        title: "Premium",
        monthlyPrice: "350",
        yearlyPrice: "320",
        description: "For growing businesses with 10 users and 25 devices.",
        features: [
            "Contact Sales for Trial",
            "No. of Users : 10",
            "Devices Enrollment : 25",
        ],
    },
    {
        title: "Enterprise",
        monthlyPrice: "1400",
        yearlyPrice: "1300",
        description: "Best for enterprises with unlimited users and 100 devices.",
        features: [
            "Contact Sales for Trial",
            "No. of Users : Unlimited",
            "Devices Enrollment : 100",
        ],
    },
];

