import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { GET_BLOCKCHAIN_INFO, GET_BLOCKCHAIN_BLOCK } from '../../../redux/types/devices.types';
import { devicesActions } from '../../../redux/slice/devices.slice';
import BlockChainMap from './BlockChainMap/BlockChainMap';
import { Grid } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import CustomTitle from 'components/shared/CustomTitle';
import BlockInfo from './BlockInfo/BlockInfo';
import { blockChainActions } from '../../../redux/slice/blockchain.slice';
import { authActions } from '../../../redux/slice/auth.slice';
import { uiActions } from '../../../redux/slice/ui.slice';

const BlockChain = () => {

    const { txnId } = useParams();
    const dispatch = useDispatch();
    const allDevices = useSelector(devicesActions.selectors.getDevices);
    const blockChain = useSelector(blockChainActions.selectors.getBlockChain)
    const blockChainBlock = useSelector(blockChainActions.selectors.getBlockChainBlock)
    const currentUser = useSelector(authActions.selectors.getCurrentUser);



    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav(""));
        dispatch({ type: GET_BLOCKCHAIN_INFO })
    }, [dispatch])


    useEffect(() => {
        if (txnId) {
            const currentDevice = allDevices.find((dev) => dev?.assetIdTxn === txnId);
            const payload = {
                orgId: currentUser?.orgId,
                deviceId: currentDevice?.deviceId
            }
            dispatch({ type: GET_BLOCKCHAIN_BLOCK, payload })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, txnId, currentUser?.orgId])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CustomTitle title="Blockchain" isCount={false} />
            </Grid>
            <Grid item xs={12} >
                <MyCard padding={2} height='300px'>
                    <BlockChainMap blockChain={blockChain} selectedBlock={blockChainBlock} />
                </MyCard>
            </Grid>
            <Grid item xs={12} >
                <MyCard height='200px'>
                    <BlockInfo block={blockChainBlock} />
                </MyCard>
            </Grid>
        </Grid>
    )
}

export default BlockChain
