import { Card, Grid } from "@mui/material";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  executeCommand,
  getSingleDevicesSocket,
  setupDashboardSocket,
  unSubscribeSocketTopics,
} from "../../../redux/saga/socket";
import { applicationsActions } from "../../../redux/slice/applications.slice";
import { devicesActions } from "../../../redux/slice/devices.slice";
import { networksActions } from "../../../redux/slice/networks.slice";
import { packagesActions } from "../../../redux/slice/packages.slice";
import { portsActions } from "../../../redux/slice/ports.slice";
import { processesActions } from "../../../redux/slice/processes.slice";
import { GET_SINGLE_DEVICE, GET_SINGLE_DEVICE_LOCATION } from "../../../redux/types/devices.types";
import { GET_DASHBOARD_ANALYTICS, GET_DEVICE_USERS } from "../../../redux/types/shared.types";
import DeleteIcon from "../../../static/svgs/Delete";
import NoneIcon from "../../../static/svgs/None";
import CustomTitle from "../../shared/CustomTitle";
import MyTable from "../../shared/Tables/MyTable/MyTable";
import MyTableToolbar from "../../shared/Tables/MyTable/MyTableToolbar";
import table from "../../shared/Tables/table.module.scss";
import { commandTypes } from "../../utils";
import { OS, TOPICS } from "../../utils/enums";
import theme from "../../utils/theme";
import { handleDeleteApps } from "../Apps/helper";
import DeviceGraph from "../Device/DeviceGraph";
import DeviceMap from "../Device/DeviceMap/DeviceMap";
import { getMarker } from "../Device/helper";
import DashboardCards from "./DashboardCards";
import DetailChartCard from "./DetailChartCard";
import SystemInfo from "./SystemInfo";
import {
  complainceDetailItems,
  getAppDetailItems,
  linuxAllAppsColumns,
  networkColumns,
  portColumns,
  processesColumns,
  windowsAllAppsColumns
} from "./helper";
import { authActions } from "../../../redux/slice/auth.slice";

const SingleDashboard = () => {
  const dispatch = useDispatch();
  const combineApps = useSelector(applicationsActions.selectors.getCombineApps);
  const networks = useSelector(networksActions.selectors.getNetworks);
  const ports = useSelector(portsActions.selectors.getPorts);
  const applications = useSelector(applicationsActions.selectors.getApplications);
  const packages = useSelector(packagesActions.selectors.getPackages);
  const processes = useSelector(processesActions.selectors.getProcesses);
  const selectedDevice = useSelector(devicesActions.selectors.getSelectedDevice);
  const singleDeviceLocation = useSelector(devicesActions.selectors.getSingleDeviceLocation);
  const currentUser = useSelector(authActions.selectors.getCurrentUser);



  useEffect(() => {
    setupDashboardSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([
        TOPICS.PROCESSES,
        TOPICS.SYSTEM,
        TOPICS.SYSTEM_PACKAGES,
        TOPICS.APPLICATIONS,
        TOPICS.NETWORK_INTERFACES,
        TOPICS.PORTS,
        TOPICS.ALL_APPS,
        TOPICS.USERS
      ]);
    };
  }, [dispatch]);



  const { id } = useParams();
  useEffect(() => {
    if (id) {
      const payload = {
        deviceId: id,
      };
      getSingleDevicesSocket({ orgId: currentUser?.orgId, _id: id });
      dispatch({ type: GET_DASHBOARD_ANALYTICS, payload });
      dispatch({ type: GET_SINGLE_DEVICE, payload });
      dispatch({ type: GET_DEVICE_USERS, payload });
      dispatch({ type: GET_SINGLE_DEVICE_LOCATION, payload });
    }
  }, [currentUser?.orgId, dispatch, id]);

  const appDetails = useMemo(() => {
    return getAppDetailItems(
      combineApps?.length,
      applications?.length,
      packages?.length
    );
  }, [combineApps?.length, applications?.length, packages?.length]);

  const markers = useMemo(() => {
    if (singleDeviceLocation?.loc) {
      const coordinates = singleDeviceLocation?.loc?.split(",").map(Number).reverse();
      if (coordinates?.length === 2) {
        const html = getMarker(theme.palette.success.main);
        return [{ coordinates, html, device: singleDeviceLocation?.device }];
      }
    }
    return [];
  }, [singleDeviceLocation]);

  const graphColors = [
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.warning.light,
  ];

  const [selectedProceses, setSelectedProceses] = useState([]);
  const handleProcessesSelection = (ids) => {
    setSelectedProceses(ids);
  };

  const [selectedPackages, setSelectedPackages] = useState([]);
  const handlePackageSelection = (ids) => {
    setSelectedPackages(ids);
  };

  const handleKillProcesses = () => {
    const filteredObjects = processes.filter((object) =>
      selectedProceses.includes(object.id)
    );
    const pids = filteredObjects?.map((item) => item?.pid);
    executeCommand(pids, commandTypes.PROCESS, selectedDevice?.uid, selectedDevice?.systemDetails?.os);

    const nonFilteredObjects = processes.filter(
      (object) => !selectedProceses.includes(object.id)
    );
    dispatch(processesActions.actions.setProcesses(nonFilteredObjects));
  };

  const handleDeletePackages = () => {
    handleDeleteApps(
      selectedPackages,
      filteredPackages,
      applicationsActions.actions.setCombineApps,
      dispatch,
      selectedDevice
    );
  };

  const [networkSearchTerm, setNetworkSearchTerm] = useState("");
  const [portSearchTerm, setPortSearchTerm] = useState("");
  const [packageSearchTerm, setPackageSearchTerm] = useState("");
  const [processSearchTerm, setProcessSearchTerm] = useState("");

  const handleNetworkSearch = (term) => {
    setNetworkSearchTerm(term);
  };

  const handlePortSearch = (term) => {
    setPortSearchTerm(term);
  };

  const handlePackageSearch = (term) => {
    setPackageSearchTerm(term);
  };

  const handleProcessSearch = (term) => {
    setProcessSearchTerm(term);
  };

  const filteredNetworks = useMemo(() => {
    if (!networkSearchTerm) return networks;
    const regex = new RegExp(networkSearchTerm, "i");
    return networks.filter((network) =>
      Object.values(network).some((value) => regex.test(value))
    );
  }, [networks, networkSearchTerm]);

  const filteredPorts = useMemo(() => {
    if (!portSearchTerm) return ports;
    const regex = new RegExp(portSearchTerm, "i");
    return ports.filter((port) =>
      Object.values(port)?.some((value) => regex.test(value))
    );
  }, [ports, portSearchTerm]);

  const filteredPackages = useMemo(() => {
    if (!packageSearchTerm) return combineApps;
    const regex = new RegExp(packageSearchTerm, "i");
    return combineApps.filter((pkg) =>
      Object.values(pkg)?.some((value) => regex.test(value))
    );
  }, [combineApps, packageSearchTerm]);

  const filteredProcesses = useMemo(() => {
    if (!processSearchTerm) return processes;
    const regex = new RegExp(processSearchTerm, "i");
    return processes.filter((process) =>
      Object.values(process)?.some((value) => regex.test(value))
    );
  }, [processes, processSearchTerm]);


  const allAppColumns = useMemo(() => {
    return selectedDevice?.systemDetails?.os === OS.windows ? windowsAllAppsColumns : linuxAllAppsColumns
  }, [selectedDevice?.systemDetails?.os]);

  return (
    <div>
      <DashboardCards />
      <Grid
        my={"15px"}
        container
        sx={{ justifyContent: { xl: "space-between", md: "center" } }}
        spacing={2}
      >
        <Grid item xs={12} md={6} lg={4}>
          <DeviceGraph />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DeviceMap
            markers={markers}
            center={singleDeviceLocation?.loc}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <DetailChartCard
            details={appDetails}
            title={"Total Apps Installed"}
            chartSeries={{
              a: combineApps?.length,
              b: applications?.length,
              c: packages?.length,
            }}
            totalValue={combineApps?.length}
            graphColors={graphColors}
          />
        </Grid>
      </Grid>
      <Grid my={"15px"} container spacing={2}>
        <Grid item md={12} lg={8}>
          <SystemInfo />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <DetailChartCard
            details={complainceDetailItems}
            title={"Total Compliance Details"}
            chartSeries={{ a: 40, b: applications?.length, c: 35 }}
            totalValue={345}
            graphColors={graphColors}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6} className={table.tableContainer}>
          <CustomTitle title={"Network Interfaces"} count={filteredNetworks?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Network"}
              onSearch={handleNetworkSearch}
              onDownload={() => handleDownloadCsv(filteredNetworks, networkColumns)}
              onPrint={() => handlePrintCsv(filteredNetworks, networkColumns)}
            />
            <MyTable
              checkboxSelection={false}
              columns={networkColumns}
              rows={filteredNetworks}
            />
          </Card>
        </Grid>
        <Grid item xs={12} xl={6} className={table.tableContainer}>
          <CustomTitle title={"Network Ports"} count={filteredPorts?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Ports"}
              onSearch={handlePortSearch}
              onDownload={() => handleDownloadCsv(filteredPorts, portColumns)}
              onPrint={() => handlePrintCsv(filteredPorts, portColumns)}
            />
            <MyTable
              checkboxSelection={false}
              columns={portColumns}
              rows={filteredPorts}
            />
          </Card>
        </Grid>
        <Grid item xs={12} xl={6} className={table.tableContainer}>
          <CustomTitle title={"All Applications"} count={filteredPackages?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Applications"}
              onSearch={handlePackageSearch}
              onDownload={() => handleDownloadCsv(filteredPackages, allAppColumns)}
              onPrint={() => handlePrintCsv(filteredPackages, allAppColumns)}
              btn1Txt={false}
              handleBtn2Click={handleDeletePackages}
              btn2Txt={"Uninstall"}
              btn2Icon={DeleteIcon}
            />
            <MyTable
              checkboxSelection={true}
              columns={allAppColumns}
              rows={filteredPackages}
              handleSelectedRows={handlePackageSelection}
              handleBtn2Click={handleKillProcesses}
            />
          </Card>
        </Grid>
        <Grid item xs={12} xl={6} className={table.tableContainer}>
          <CustomTitle title={"Processes"} count={filteredProcesses?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Processes"}
              onSearch={handleProcessSearch}
              onDownload={() => handleDownloadCsv(filteredProcesses, processesColumns)}
              onPrint={() => handlePrintCsv(filteredProcesses, processesColumns)}
              handleBtn2Click={handleKillProcesses}
              btn1Txt={false}
              btn2Txt={"Kill processes"}
              btn2Icon={NoneIcon}
            />
            <MyTable
              checkboxSelection={true}
              columns={processesColumns}
              rows={filteredProcesses}
              handleSelectedRows={handleProcessesSelection}
            />
          </Card>
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default SingleDashboard;
