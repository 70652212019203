import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orgGroupsActions } from "../../../../redux/slice/orgGroups.slice";
import { uiActions } from "../../../../redux/slice/ui.slice";
import PolicyAlerts from "../AccessManagement/Policies/PolicyAlerts";
import ManagementHeader from "../ManagementHeader/ManagementHeader";
import DevicePolicies from "./DevicePolicies/DevicePolicies";
import Devices from "./Devices/Devices";
import Employees from "./Employees/Employees";
import { getOrdManagmentResources, orgCardcolors, orgNavItems } from "./helper";
import OrgGroups from "./OrgGroups/OrgGroups";
import { employeesActions } from "../../../../redux/slice/employees.slice";
import { devicesActions } from "../../../../redux/slice/devices.slice";
import { alertPolicyActions } from "../../../../redux/slice/alertPolicy.slice";
import { devicePolicyActions } from "../../../../redux/slice/devicePolicy.slice";

const OrgManagement = () => {
    const dispatch = useDispatch();
    const [selectedTab, handleSelectedTab] = useState(0);
    const policies = useSelector((state) => state.policy.policies);

    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav('Asset Management'))
    }, [dispatch]);


    const employees = useSelector(employeesActions.selectors.getEmployees);
    const devices = useSelector(devicesActions.selectors.getDevices);
    const orgGroups = useSelector(orgGroupsActions.selectors.getOrgGroups)
    const alertPolicies = useSelector(alertPolicyActions.selectors.getAlertPolicies);
    const devicePolicies = useSelector(devicePolicyActions.selectors.getDevicePolicy)

    const renderContent = (selectedTab) => {
        switch (selectedTab) {
            case 0:
                return <OrgGroups />;
            case 1:
                return <Employees />;
            case 2:
                return <Devices />;
            case 3:
                return <PolicyAlerts />;
            case 4:
                return <DevicePolicies />
            default:
                return <OrgGroups />;
        }
    };

    const resources = useMemo(() => {
        return getOrdManagmentResources({ groups: orgGroups?.length, employees: employees?.length, devices: devices?.length, alertPolicies: alertPolicies?.length, policies: devicePolicies?.length });
    }, [orgGroups?.length, employees?.length, devices?.length, alertPolicies?.length, devicePolicies?.length])

    return (
        <>
            <ManagementHeader
                selectedTab={selectedTab}
                handleSelectedTab={handleSelectedTab}
                resources={resources}
                cardColors={orgCardcolors}
                navitems={orgNavItems}
                title="Assets Management"
            />
            {renderContent(selectedTab)}
        </>
    );
};

export default OrgManagement;
