import { Popover, Typography } from '@mui/material'
import React from 'react'
import styles from './popover.module.scss';

const CustomPopover = ({ open, anchorEl, handlePopoverClose, popoverText }) => {
    return (
        <Popover
            id="mouse-over-popover"
            classes={{ paper: styles['MuiPopover-paper'] }}
            className={styles.popover}
            open={open}
            anchorEl={anchorEl}
            disableScrollLock={true}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
        >
            <Typography className={styles.text}>
                {popoverText}
            </Typography>
        </Popover>

    )
}

export default CustomPopover
