export const generateLabel = (device, parentLabel = "") => {
    const labelPrefix = parentLabel ? `${parentLabel}C` : "P";
    const index = device.index !== undefined ? device.index + 1 : 1;
    return `${labelPrefix}${index}`;
};

export const addLabels = (devices, parentLabel = "") => {
    return devices.map((device, index) => {
        const label = generateLabel({ ...device, index }, parentLabel);
        const updatedDevice = { ...device, label };
        if (device.childDevices) {
            updatedDevice.childDevices = addLabels(device.childDevices, label);
        }
        return updatedDevice;
    });
};

export const mapHeaderLabels = [
    { label: "Network ID", style: { textAlign: 'left', width: 190, }, },
    { label: "Device Name", style: { textAlign: 'left', width: 150 } },
    { label: "IP Address", style: { width: 110 } },
    { label: "No of Devices", style: { width: 110 } },
    { label: "Device ID", style: { textAlign: 'left', width: 190 } },
    { label: "Status", style: { textAlign: 'center', width: 150 } },
    { label: "Txn", style: { textAlign: 'center', width: 100 } },
    { label: "Action", style: { textAlign: 'center', width: 100 } },
];

export const handleOpenBlockHash = (assetIdTxn) => {
    const link = `/blockchain/tx/${assetIdTxn}`;
    window.open(link, "_blank");
}


export const networkCSVColums = [
    {
        field: 'deviceId',
        headerName: 'Device ID',
    },

    {
        field: 'networkId',
        headerName: 'Network ID',
    },
    {
        field: 'status',
        headerName: 'Status',
    },
];

