import copy from 'copy-to-clipboard';
import { memo } from 'react';
import toast from 'react-hot-toast';
import { installationSteps } from '../../../static/mock';
import CopyIcon from '../../../static/svgs/Copy';
import CustomDialog from '../../shared/Dialog/CustomDialog';
import CustomSelect from '../../shared/Forms/CustomSelect';
import text from '../../shared/css/text.module.scss';
import shared from '../../shared/shared.module.scss';
import theme from '../../utils/theme';
import styles from './device.module.scss';
import { osOptions, prepareUbuntuCommand, prepareWindowsCommand, ubuntuVersions, windowsVersions } from './helper';

const AddDeviceDialog = ({ isDeviceOpen, handleDeviceDialog, curlCommand, setCurlCommand, copied, setCopied, selectedOs, setSelectedOs, selectedVersion, setSelectedVersion, selectedDeviceToken }) => {

    const handleChange = (event) => {
        setSelectedOs(event.target.value);
        // eslint-disable-next-line default-case
        switch (event.target.value) {
            case 'windows':
                setCurlCommand(prepareWindowsCommand(selectedDeviceToken))
                return setSelectedVersion(windowsVersions?.[0]?.value);
            case 'ubuntu':
                setCurlCommand(prepareUbuntuCommand(selectedDeviceToken))
                return setSelectedVersion(ubuntuVersions?.[0]?.value)
        }
    };
    const handleVersion = (event) => {
        setSelectedVersion(event.target.value);
    };

    const handleCopy = () => {
        copy(curlCommand);
        setCopied(true)
        toast.success('Copied')
    }

    const handleVersionOptions = (value) => {
        // eslint-disable-next-line default-case
        switch (value) {
            case 'windows':
                return windowsVersions;
            // case 'mac':
            //     return macVersions;
            case 'ubuntu':
                return ubuntuVersions

        }
    }
    return (
        <CustomDialog title={'Installation Steps:'} isOpen={isDeviceOpen} handleToggle={handleDeviceDialog} maxWidth={'sm'}>
            <div className={styles.messageContainer}>
                <p className={text.normal2}>Choose installation steps:</p>
                {installationSteps?.map((item, idx) => (
                    <div key={idx} className={styles.textItem}>
                        <div className={`${shared.circle} ${styles.bgBlue}`} > </div>
                        <p className={text.normal1}>{item}</p>
                    </div>
                ))}
            </div>
            <div>
                <br />
                <CustomSelect
                    label="Select OS"
                    required
                    options={osOptions}
                    value={selectedOs}
                    onChange={handleChange}
                />
                <br />
                <CustomSelect
                    label="Select Version"
                    required
                    options={handleVersionOptions(selectedOs)}
                    value={selectedVersion}
                    onChange={handleVersion}
                />
                <br />
            </div>
            <div className={styles.row}>
                <p className={text.formLabel}>Command:</p>
                <div style={{ backgroundColor: copied ? theme.palette.primary.light : theme.palette.primary.main }} onClick={handleCopy} className={styles.copyBtn}>
                    <CopyIcon />
                </div>
            </div>
            <br />
            <p className={`${text.small1} ${styles.commandText}`}>{curlCommand}</p>
            <br />
        </CustomDialog>
    );
}

export default memo(AddDeviceDialog)

