import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../../redux/slice/auth.slice";
import text from "../css/text.module.scss";
import ProfileMenu from "./ProfileMenu/ProfileMenu";

const Navbar = () => {
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate("/account");
  };

  return (
    <Grid
      container
      sx={{ flexDirection: { md: "row", xs: "column" }, alignItems: "center" }}
    >
      <Grid
        item
        sm={12}
        md={5}
        sx={{ justifyContent: { md: "flex-start", sm: "center" } }}
      >
        <Typography className={text.heading1}>
          {`Welcome ${currentUser?.username}!`}
        </Typography>
        <Typography className={text.info1}>Hope you have a nice afternoon</Typography>
      </Grid>
      <Grid
        display={"flex"}
        gap={3}
        alignItems={"center"}
        justifyContent={"flex-end"}
        item
        sm={12}
        md={7}
      >
        {/* <Grid item className={styles.searchBoxContainer}>
          <SearchTextField placeholder={"Search for item, etc"} />
        </Grid> */}
        <Grid item alignItems={"center"} display={"flex"} gap={2}>
          <div onClick={handleSettingsClick}>
            {/* <SettingsIcon /> */}
          </div>
          {/* <NotificationsButton /> */}
          {/* <Divider className={styles.verticleDivider} orientation="vertical" /> */}
          <ProfileMenu user={currentUser} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navbar;
