export const FETCH_GROUPS = "FETCH_GROUPS";
export const CREATE_GROUP = "CREATE_GROUP";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const GROUPS_FAILURE = "GROUPS_FAILURE";
export const ADD_USER_TO_GROUP = "ADD_USER_TO_GROUP";
export const ADD_APP_TO_GROUP = "ADD_APP_TO_GROUP";
export const GET_USERS = "GET_USERS";
export const GET_USER_INVITES = "GET_USER_INVITES";
export const RESEND_INVITE = "RESEND_INVITE";

