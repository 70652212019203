export const GET_DEVICES = "GET_DEVICES";
export const GET_DEVICE_TOKEN = "GET_DEVICE_TOKEN";
export const GET_SINGLE_DEVICE = "GET_SINGLE_DEVICE";
export const GET_SINGLE_DEVICE_LOCATION = "GET_SINGLE_DEVICE_LOCATION";
export const GET_DEVICES_LOCATION = "GET_DEVICES_LOCATION";
export const DELETE_DEVICES = "DELETE_DEVICES";
export const FETCH_ALERTS = "FETCH_ALERTS";
export const GET_MALWARE_HASHES = "GET_MALWARE_HASHES";
export const UPDATE_DEVICE_NAME = "UPDATE_DEVICE_NAME";
export const GET_BLOCKCHAIN_INFO = "GET_BLOCKCHAIN_INFO";
export const GET_BLOCKCHAIN_BLOCK = "GET_BLOCKCHAIN_BLOCK";
