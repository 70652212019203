import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceToken: {},
  devices: [],
  selectedDevice: {},
  currentDevice: {},
  deviceLocation: [],
  singleDeviceLocation: {},
  selectedDevicesAll: [],
  alerts: []
};

const name = "devices";
const parentSelector = (state) => state?.[name];

const devicesSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    resetDevices: () => {
      return initialState;
    },
    setDevicesToken: (state, action) => {
      return {
        ...state,
        deviceToken: action.payload,
      };
    },
    setDevices: (state, action) => {
      return {
        ...state,
        devices: action.payload,
      };
    },
    setSelectedDevice: (state, action) => {
      return {
        ...state,
        selectedDevice: action.payload,
      };
    },
    setCurrentDevice: (state, action) => {
      return {
        ...state,
        currentDevice: action.payload,
      };
    },
    setDeviceLocation: (state, action) => {
      return {
        ...state,
        deviceLocation: action.payload,
      };
    },
    setSingleDeviceLocation: (state, action) => {
      return {
        ...state,
        singleDeviceLocation: action.payload,
      };
    },
    setSelectedDevicesAll: (state, action) => {
      return {
        ...state,
        selectedDevicesAll: action.payload,
      };
    },
    setAlerts: (state, action) => {
      return {
        ...state,
        alerts: action.payload,
      };
    },

  },
});

const getDeviceToken = createSelector(parentSelector, (state) => {
  return state?.deviceToken;
});

const getDevices = createSelector(parentSelector, (state) => {
  return state?.devices;
});

const getSelectedDevice = createSelector(parentSelector, (state) => {
  return state?.selectedDevice;
});

const getCurrentDevice = createSelector(parentSelector, (state) => {
  return state?.currentDevice;
});

const getDeviceLocation = createSelector(parentSelector, (state) => {
  return state?.deviceLocation;
});

const getSingleDeviceLocation = createSelector(parentSelector, (state) => {
  return state?.singleDeviceLocation;
});

const getSelectedDevicesAll = createSelector(parentSelector, (state) => {
  return state?.selectedDevicesAll;
});

const getAlerts = createSelector(parentSelector, (state) => {
  return state?.alerts;
});

const getBlockChainInfo = createSelector(parentSelector, (state) => {
  return state?.blockChainInfo;
});

const getBlockChainBlock = createSelector(parentSelector, (state) => {
  return state?.blockChainBlock;
});

const actions = {
  ...devicesSlice.actions,
};

const selectors = {
  getDeviceToken,
  getDevices,
  getSelectedDevice,
  getCurrentDevice,
  getDeviceLocation,
  getSingleDeviceLocation,
  getSelectedDevicesAll,
  getAlerts,
  getBlockChainInfo,
  getBlockChainBlock
};

export const devicesActions = { actions, selectors };

export default devicesSlice;
