import toast from "react-hot-toast";
import { put, takeEvery } from "redux-saga/effects";
import { SOMETHING_WENT_WRONG } from "../../static/constants";
import { getSubscriptionPlansServ } from "../services/subscription.service";
import { subscriptionActions } from "../slice/subscription.slice";
import { GET_SUBSCRIPTION_PLANS } from "../types/subscription.types";

export function* getSubscriptionPlans() {
  try {
    const plans = yield getSubscriptionPlansServ();
    if (plans) {
      yield put(subscriptionActions.actions.setSubscriptionPlans(plans?.data));
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}






export function* watchSubscriptionAsync() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
}
