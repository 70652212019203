import React, { useEffect, useState } from 'react';
import styles from './pricingPlan.module.scss';
import { Grid, Stack, Typography } from '@mui/material';
import text from "../../shared/css/text.module.scss";
import { AntSwitch, pricingPlans } from './helper';
import PricingPlanCard from './PricingPlanCard';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SUBSCRIPTION_PLANS } from '../../../redux/types/subscription.types';
import { subscriptionActions } from '../../../redux/slice/subscription.slice';
import PricingSkeleton from './PricingSekelton';

const PricingBody = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: GET_SUBSCRIPTION_PLANS })
    }, [dispatch]);

    const subscriptionPlans = useSelector(subscriptionActions.selectors.getSubscriptionPlans)
    console.log("subscriptionPlans", subscriptionPlans);
    const [isYearly, setIsYearly] = useState(true);

    const handleSwitchChange = (event) => {
        setIsYearly(event.target.checked);
    };

    return (
        <div className={styles.pricingBody}>
            <Typography className={text.heading1} color="primary">Plans & Pricing</Typography>
            <Typography className={styles.mainTitle}>Empowering Businesses with Zero Trust Security and Compliance</Typography>
            <Typography className={text.heading2}>Choose the plan that fits your needs and start transforming your operations today.</Typography>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography style={{ color: !isYearly ? 'inherit' : 'gray' }}>Billed Yearly</Typography>
                <AntSwitch
                    checked={isYearly}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'ant design' }}
                />
                <Typography style={{ color: isYearly ? 'inherit' : 'gray' }}>Billed Monthly</Typography>
            </Stack>
            <br />
            <Grid container item xs={12} spacing={2} px={4} justifyContent={"space-between"} alignItems={"center"}>
                {subscriptionPlans?.length ?
                    <>
                        {subscriptionPlans.map((plan, index) => (
                            <Grid item key={index} xs={12} md={3.9}>
                                <PricingPlanCard plan={plan} isYearly={isYearly} />
                            </Grid>
                        ))}
                    </> :
                    <>
                        {[...Array(3)].map((_, index) => (
                            <Grid item key={index} xs={12} md={3.9}>
                                <PricingSkeleton />
                            </Grid>
                        ))}
                    </>
                }

            </Grid>
            <br />

        </div >
    );
};

export default PricingBody;
