import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchPoliciesService, createPolicyService, updatePolicyService, deletePolicyService, assignPolicyToGroupService } from "../services/policy.service";
import { policyActions } from "../slice/policy.slice";
import { ASSIGN_POLICY_TO_GROUP, CREATE_POLICY, DELETE_POLICY, FETCH_POLICIES, INVITE_USER, UPDATE_POLICY } from "../types/policy.types";
import toast from "react-hot-toast";
import { inviteUserService, resendInviteService } from "../services/group.service";
import { FETCH_GROUPS, RESEND_INVITE } from "../types/group.types";
import { groupActions, groupSelectors } from "../slice/group.slice";

function* fetchPoliciesSaga() {
  try {
    const policies = yield call(fetchPoliciesService);
    yield put(policyActions.fetchPoliciesSuccess(policies));
  } catch (error) {
    yield put(policyActions.fetchPoliciesFailure(error.message));
  }
}

function* createPolicySaga(action) {
  try {
    const policy = yield call(createPolicyService, action.payload);
    yield put(policyActions.createPolicySuccess(policy));
  } catch (error) {
    yield put(policyActions.createPolicyFailure(error.message));
  }
}

function* updatePolicySaga(action) {
  try {
    const policy = yield call(updatePolicyService, action.payload);
    yield put(policyActions.updatePolicySuccess(policy));
  } catch (error) {
    yield put(policyActions.updatePolicyFailure(error.message));
  }
}

function* deletePolicySaga(action) {
  try {
    const response = yield call(deletePolicyService, action.payload);
    yield put(policyActions.deletePolicySuccess(action.payload));
  } catch (error) {
    yield put(policyActions.deletePolicyFailure(error.message));
  }
}

function* inviteUserSaga(action) {
  try {
    const response = yield call(() =>
      toast.promise(
        inviteUserService(action?.payload),
        {
          loading: 'Sending Invite...',
          success: 'Invite Sent successfully',
          error: 'Failed to Send Invite. Please Re-check your Email',
        }
      )
    );

    if (response) {
      const prevInvites = yield select(groupSelectors.getInvites);
      yield put(groupActions.setInvites([response?.data, ...prevInvites]))
    }
  } catch (error) {
    console.log("error:", error?.message)
  }
}

function* resendInviteSaga(action) {
  try {
    const response = yield call(() =>
      toast.promise(
        resendInviteService(action?.payload),
        {
          loading: 'Re-Sending Invite...',
          success: 'Invite Sent successfully',
          error: (err) => {
            console.log("err", err);
            return err?.response?.data?.message
          },
        }
      )
    );

    if (response) {
      const invites = yield select(groupSelectors.getInvites);
      console.log("response", response, invites)
      invites?.forEach((invite) => {
        if (invite?.userId === response?.data?.userId) {
          invite = response?.data
        }
      })
      yield put(groupActions.setInvites(invites))
    }
  } catch (error) {
    console.log("error:", error?.message)
  }
}

function* assignPolicyToGroupSaga(action) {
  try {
    yield call(assignPolicyToGroupService, action.payload)
    toast.success("Policy Assigned to group");
    yield put({ type: FETCH_GROUPS });
  } catch (error) {
    toast.error("Failed to assign policies to group")
  }
}

export function* watchPolicyAsync() {
  yield takeEvery(FETCH_POLICIES, fetchPoliciesSaga);
  yield takeEvery(CREATE_POLICY, createPolicySaga);
  yield takeEvery(UPDATE_POLICY, updatePolicySaga);
  yield takeEvery(DELETE_POLICY, deletePolicySaga);
  yield takeEvery(INVITE_USER, inviteUserSaga)
  yield takeEvery(ASSIGN_POLICY_TO_GROUP, assignPolicyToGroupSaga)
  yield takeEvery(RESEND_INVITE, resendInviteSaga)
}
