import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import CustomDrawer from "./CustomDrawer";
import { customTableStyles } from "./helper";
import tables from "./table.module.scss";
import { useDispatch } from "react-redux";
import { devicesActions } from "../../../redux/slice/devices.slice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { DeviceStatus } from "../../utils/enums";

export default function CustomTable({
  toggleDrawer,
  isDrawerOpen,
  selectedRow,
  setSelectedRow,
  devices,
  deviceCols,
  handleSelectedRows,
  handleRowUpdate = () => { }
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRowClick = (data) => {
    toggleDrawer();
    setSelectedRow(data?.row);
  };

  const handleDetailClick = (selectedRow) => {
    if (selectedRow?.status === DeviceStatus.registered || selectedRow?.status === DeviceStatus.activated) {
      toast.error("Device is not Registered or Activated yet")
      return
    }
    dispatch(devicesActions.actions.setSelectedDevice(selectedRow));
    navigate(`/single-dashboard/${selectedRow?._id}`);
  };

  const [sortModel, setSortModel] = React.useState([]);


  return (
    <div className={tables.dataGridContainer}>
      <CustomDrawer
        handleDetailClick={handleDetailClick}
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
        selectedRow={selectedRow}
      />
      <Box
        sx={{ width: { xs: "600px", sm: "900px", md: "1100px", lg: "100%" } }}
      >
        <DataGrid
          rows={devices}
          columns={deviceCols}
          sx={{
            ...customTableStyles,
            height: "550px",
          }}
          checkboxSelection={true}
          disableRowSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onRowClick={(data) => handleRowClick(data)}
          onRowSelectionModelChange={(ids) => {
            handleSelectedRows(ids);
          }}
          processRowUpdate={(newRow, oldRow) => handleRowUpdate(newRow, oldRow)}
          getRowId={(row) => row?._id}
        />
      </Box>
    </div>
  );
}
