import MultiAlerts from 'components/modules/MultiDashboard/MultiAlerts';
import CustomNavTabs from 'components/shared/CustomNavTabs';
import { multiDashboardNavItems } from 'components/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiDashboard from '../components/modules/MultiDashboard/MultiDashboard';
import { TOPICS } from '../components/utils/enums';
import { setupMultiDashboardSocket, unSubscribeSocketTopics } from '../redux/saga/socket';
import { uiActions } from '../redux/slice/ui.slice';

const MultiDashboardPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav('Dashboard'))
        setupMultiDashboardSocket(dispatch)
        return () => {
            unSubscribeSocketTopics([TOPICS.PROCESSES, TOPICS.SYSTEM, TOPICS.SYSTEM_PACKAGES, TOPICS.APPLICATIONS, TOPICS.NETWORK_INTERFACES, TOPICS.PORTS, TOPICS.ALL_APPS]);
        };
    }, [dispatch]);

    const selectedTab = useSelector(uiActions.selectors.getSelectedMultiTab);

    const handleSelectedTab = (value) => {
        dispatch(uiActions.actions.setSelectedMultiTab(value))
    };


    const handleComponents = (tab) => {
        // eslint-disable-next-line default-case
        switch (tab) {
            case 0:
                return <MultiDashboard />;
            case 1:
                return <MultiAlerts />
            default:
                return <MultiDashboard />
        }
    };

    return (
        <div>
            <CustomNavTabs
                selectedTab={selectedTab}
                handleSelectedTab={handleSelectedTab}
                navitems={multiDashboardNavItems}
            />
            {console.log("selectedTab", selectedTab)}
            {handleComponents(selectedTab)}
        </div>
    )
}

export default MultiDashboardPage
