import { Badge, Button } from "@mui/material";
import { formatDateBdata } from "components/utils";
import theme from "components/utils/theme";
import tables from '../../../../shared/Tables/table.module.scss'

export const getInvitesColumns = ({ handleResendInvite }) => [
    { field: "email", headerName: "Email", flex: 1.5 },
    { field: "adminName", headerName: "Invited By", flex: 1 },
    {
        field: "createdAt", headerName: "Invited on",
        renderCell: (params) => formatDateBdata(params?.row?.createdAt),
        flex: 1
    },
    {
        field: "status",
        headerName: "Status",
        renderCell: (params) => getInviteStatusBadge(params?.row?.status),
        flex: 1,
    },
    {
        field: "actions",
        headerName: "Actions",
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => {
            return (
                <>
                    {params.row.status === "pending" ?
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleResendInvite(params?.row?._id)
                            }}
                            className={tables.actionBtn}
                            size="small"
                        >
                            Resend Invite
                        </Button> : "-"
                    }
                </>
            )
        },
    },
];


const getInviteStatusBadge = (status) => {
    console.log("sta", status)
    switch (status) {
        case "pending":
            return <Badge badgeContent={status} sx={{
                "& .MuiBadge-badge": {
                    backgroundColor: theme.palette.warning.main,
                    color: theme.palette.white.main,
                },
            }} />
        case "accepted":
            return <Badge badgeContent={status}
                sx={{
                    "& .MuiBadge-badge": {
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.white.main,
                    },
                }}
            />
        default:

    }
}
export const userColumns = [
    { field: "username", headerName: "User Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1.5 },
    { field: "role", headerName: "Role", flex: 0.5 },
    { field: "gender", headerName: "Gender", flex: 0.5 },
    {
        field: "createdAt", headerName: "Joined on",
        renderCell: (params) => formatDateBdata(params?.row?.createdAt),
        flex: 1.5
    },
];