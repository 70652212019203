import { all } from 'redux-saga/effects'
import { watchAuthAsync } from './auth.saga'
import { watchDeviceAsync } from './devices.saga'
import { watchSingleAsync } from './single.saga'
import { watchMultiAsync } from './multi.saga'
import { watchPolicyAsync } from './policy.saga';
import { watchGroupAsync } from "./group.saga";
import { watchAlertPolicyAsync } from './alertPolicy.saga';
import { watchSettingsAsync } from './settings.saga';
import { watchNetworkDeviceAsync } from './networkDevice.saga'
import { watchEmployeeAsync } from './employees.saga'
import { watchOrgGroupAsync } from './organization.saga'
import { watchSubscriptionAsync } from './subscription.saga'

export function* rootSaga() {
  yield all([
    watchAuthAsync(),
    watchSingleAsync(),
    watchDeviceAsync(),
    watchMultiAsync(),
    watchPolicyAsync(),
    watchGroupAsync(),
    watchAlertPolicyAsync(),
    watchSettingsAsync(),
    watchNetworkDeviceAsync(),
    watchEmployeeAsync(),
    watchOrgGroupAsync(),
    watchSubscriptionAsync()
  ])
}
