import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import theme from 'components/utils/theme';
import styles from './pricingPlan.module.scss';

const PricingPlanCard = ({ plan, isYearly }) => {
    return (
        <Card className={styles.priceCard}>
            <CardContent>
                <Typography className={styles.priceCardTitle}>{plan?.name}</Typography>
                <Typography className={styles.priceCardPrice}>
                    <span className={styles.priceCardCurrency}>$</span>
                    {!isYearly ? plan?.yearlyPrice : plan?.monthlyPrice}
                    <span className={styles.priceCardDuration}>/month</span>
                </Typography>
                <Typography className={styles.priceCardDescription}>{plan?.description}</Typography>
                <Typography className={styles.priceCardFeaturesHeading}>What’s included</Typography>
                <Stack spacing={1} className={styles.priceCardFeatureList}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        className={styles.priceCardFeatureItem}
                    >
                        <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                        <Typography> {plan?.freeTrialDays ? `${plan?.freeTrialDays} days Free Trail` : "Contact Sales for Free Trail"}</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        className={styles.priceCardFeatureItem}>
                        <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                        <Typography>No. of Users : {plan?.userLimit ? plan?.userLimit : "Unlimited"}</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        className={styles.priceCardFeatureItem}
                    >
                        <CheckCircleIcon className={styles.priceCardFeatureIcon} />
                        <Typography>No. of Device : {plan?.deviceLimit ? plan?.deviceLimit : "Unlimited"}</Typography>

                    </Stack>
                </Stack>
                <CustomButton
                    padding={"0px 50px"}
                    fullWidth={true}
                    color={theme.palette.background.default}
                    bgColor={theme.palette.primary.main}
                    hover={theme.palette.info.main}
                    label={"Get Started"}
                    type={"submit"}
                    height="35px"
                    variant='outlined'
                />

            </CardContent>
        </Card>
    );
};

export default PricingPlanCard;
