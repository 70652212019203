// MapSummaryItem.js
import { MoreVert } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { getBadge } from "components/shared/Tables/helper";
import { DeviceStatus } from "components/utils/enums";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { devicesActions } from "../../../../../redux/slice/devices.slice";
import styles from "./MapSummary.module.scss";
import { Button } from "rsuite";
import tables from "../../../../shared/Tables/table.module.scss";
import { handleOpenBlockHash } from "./helper";
import { sendToSocket } from "../../../../../redux/saga/socket";
import { INITIALIZE_RESCAN } from "../../../../../redux/types/networkDevice.types";


const MapSummaryItem = ({
  item,
  handleInstallDevice,
  selectedRows,
  handleRowSelect,
  level = 0
}) => {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded ? panelId : false);
  };

  const panelId = `panel-${level}-${item.deviceId}`;
  const hasChildDevices = item?.childDevices?.length > 0;

  const isSelected = selectedRows.indexOf(item?._id) !== -1;

  const handleCheckBoxSelect = (event, id) => {
    handleRowSelect(event, id);
    event.stopPropagation();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = (event, type, row) => {
    event.stopPropagation();
    switch (type) {
      case "install":
        handleInstallDevice(row?.parentDevice?.token);
        break;
      case "txn":
        const link = `https://sepolia.etherscan.io/tx/${row?.assetIdTxn || row?.parentDevice?.assetIdTxn}`;
        window.open(link, "_blank");
        break;
      case "deviceDetails":
        if (row?.parentDevice) {
          navigate(`/single-dashboard/${row?.parentDevice?._id}`);
        } else {
          navigate(`/single-dashboard/${row?._id}`);
        }
        dispatch(devicesActions.actions.setSelectedDevice(row));
        break;
      case "networkDetails":
        navigate(`/single-network/${row?._id}`);
        console.log("navigate to device details", row?._id);
        break;
      case "reScanNetwork":
        const payload = {
          networkId: row?.networkId,
          uid: row?.parentDevice?.uid,
          registeredIPs: row?.localIps
        }
        sendToSocket("INITIALIZE_RESCAN", payload)
        dispatch({ type: INITIALIZE_RESCAN, payload });
        break;
      default:
        console.log("");
    }
    setAnchorEl(null);
  };

  return (
    <Accordion
      key={panelId}
      expanded={hasChildDevices ? expanded === panelId : false}
      onChange={hasChildDevices ? handleChange : null}
      sx={{ marginLeft: level * 2.5, boxShadow: "none" }}
    >
      <AccordionSummary
        className={styles.tableRow}
        expandIcon={
          hasChildDevices ? (
            <ExpandMoreIcon />
          ) : (
            <Box sx={{ width: "24px", height: "24px" }} />
          )
        }
      >
        <Box display={"flex"} alignItems={"flex-start"}>
          {!level &&
            <Checkbox
              className={styles.rowCheck}
              size="small"
              checked={isSelected}
              onChange={(event) => handleCheckBoxSelect(event, item?._id)}
            />
          }
        </Box>
        <Typography noWrap style={{ width: 190 }} className={styles.rowItem}>
          {item?.networkId}
        </Typography>
        <Typography noWrap style={{ width: 150 }} className={styles.rowItem}>
          {item?.deviceName || item?.parentDevice?.deviceName}
        </Typography>
        <Typography noWrap style={{ width: 110 }} className={styles.rowItem}>
          {item?.internalIp || item?.parentDevice?.internalIp}
        </Typography>
        <Typography noWrap style={{ width: 110, textAlign: 'center' }} className={styles.rowItem}>
          {item?.childDevices?.length}
        </Typography>
        <Typography noWrap style={{ width: 190 }} className={styles.rowItem}>
          {item?.deviceId}
        </Typography>
        <Typography noWrap style={{ width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.rowItem}>
          {getBadge(item?.status)}
        </Typography>

        <Box style={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.rowItem}>
          {(item.status === DeviceStatus.connected ||
            item?.status === DeviceStatus.disconnected ||
            item?.status === DeviceStatus.partialConnected
          ) ?
            <Button
              variant="contained"
              onClick={(event) => {
                handleOpenBlockHash(item?.assetIdTxn || item?.parentDevice?.assetIdTxn);
              }}
              className={tables.actionBtn}
              size="small"
            >
              Txn
            </Button> : "-"
          }
        </Box>

        <Box style={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.rowItem}>
          {(item?.status !== DeviceStatus.registered && item?.localIps?.length) ?
            <Link to={`/ip-table/${item?._id}`}>IP Table</Link>
            : null}
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ p: 0 }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {item?.status === DeviceStatus.registered && item?.parentDevice?.isParentDevice ? (
              <MenuItem onClick={(e) => handleClose(e, "install", item)}>
                Install
              </MenuItem>
            ) : (
              <>
                {(item.status === DeviceStatus.connected ||
                  item?.status === DeviceStatus.disconnected ||
                  item?.status === DeviceStatus.partialConnected
                ) && (
                    <MenuItem onClick={(e) => handleClose(e, "txn", item)}>
                      Txn
                    </MenuItem>
                  )}
              </>
            )}
            {item?.parentDevice && (
              <MenuItem onClick={(e) => handleClose(e, "networkDetails", item)}>
                View Network Details
              </MenuItem>
            )}
            {item?.parentDevice && (item.status === DeviceStatus.connected ||
              item?.status === DeviceStatus.disconnected
              || item?.status === DeviceStatus.partialConnected
            ) && (
                <MenuItem onClick={(e) => handleClose(e, "reScanNetwork", item)}>
                  Re-scan Network
                </MenuItem>
              )}

            {(item.status === DeviceStatus.connected ||
              item?.status === DeviceStatus.disconnected ||
              item?.status === DeviceStatus.partialConnected
            ) &&
              <MenuItem onClick={(e) => handleClose(e, "deviceDetails", item)}>
                View Device Details
              </MenuItem>
            }
          </Menu>
        </Box>
      </AccordionSummary>
      {
        hasChildDevices && (
          <AccordionDetails>
            {item.childDevices.map((childItem, index) => (
              <MapSummaryItem
                key={`${panelId}-child-${index}`}
                item={childItem}
                handleInstallDevice={handleInstallDevice}
                selectedRows={selectedRows}
                handleRowSelect={handleRowSelect}
                level={level + 1}
              />
            ))}
          </AccordionDetails>
        )
      }
    </Accordion >
  );
};

export default MapSummaryItem;
