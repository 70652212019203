import { Fullscreen } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { formatDateBdata } from "components/utils";
import theme from "components/utils/theme";
import cytoscape from "cytoscape";
import { memo, useEffect, useRef, useState } from "react";
import text from '../../../shared/css/text.module.scss';
import styles from "./BlockChainMap.module.scss";
import { BlockChainBlockUrl } from "./helper";

const BlockChainMap = ({ blockChain, selectedBlock }) => {

    const cyRef = useRef(null);

    const [tooltipData, setTooltipData] = useState({
        open: false,
        x: 0,
        y: 0,
        blockInfo: null,
    });

    useEffect(() => {
        const nodes = blockChain?.map((block) => {

            return {
                data: {
                    id: `block-${block.index}`,
                    label: block.index === 0 ? `Genesis Block` : `Block ${block.index}`,
                    ...block,
                },
            };
        });


        const edges = blockChain
            ?.filter((block) => block.index > 0)
            .map((block) => ({
                data: {
                    source: `block-${block.index - 1}`,
                    target: `block-${block.index}`,
                },
            }));

        const cy = cytoscape({
            container: cyRef.current,
            elements: [...nodes, ...edges],
            style: [
                {
                    selector: "node",
                    style: {
                        "background-image": BlockChainBlockUrl,
                        "background-fit": "contain",
                        "border-color": theme.palette.primary.main,
                        "border-width": 2,
                        "background-color": "white",
                        width: "100px",
                        height: "100px",
                        label: "data(label)",
                        "text-valign": "bottom",
                        "text-halign": "center",
                        "text-margin-y": 5,
                        "color": theme.palette.primary.main,
                        "font-size": "14px",
                    },
                },
                {
                    selector: "edge",
                    style: {
                        width: 3,
                        "line-color": theme.palette.gray.main,
                        "target-arrow-color": theme.palette.text.primary,
                        "target-arrow-shape": "triangle",
                        "target-arrow-scale": 8,
                        "curve-style": "bezier",
                    },
                },
            ],
            layout: {
                name: "grid",
                rows: 1,
                spacingFactor: 1.5,
            },
        });

        // Tooltip events
        cy.on("mouseover", "node", (event) => {
            const node = event.target;
            const { x, y } = event.renderedPosition;

            setTooltipData({
                open: true,
                x,
                y,
                blockInfo: node.data(),
            });
        });

        cy.on("mouseout", "node", () => {
            setTooltipData((prev) => ({ ...prev, open: false }));
        });

        return () => cy.destroy();
    }, [blockChain, selectedBlock]);


    return (
        <div className={styles.blockMapContainer}>
            <div className={styles.blockMapTitle}>
                <Typography className={text.heading2}>Blockchain Blocks</Typography>
                <IconButton  >
                    <Fullscreen />
                </IconButton>
            </div>
            <div
                ref={cyRef}
                className={styles.blockMap}
            ></div>

            <Tooltip
                open={tooltipData.open}
                classes={{ tooltip: styles.customTooltip }}
                title={
                    tooltipData.blockInfo ? (
                        <div>
                            <div>Nonce: {tooltipData.blockInfo.nonce}</div>
                            <div>Hash: {tooltipData.blockInfo.hash.slice(0, 30)}...</div>
                            <div>Time Stamp: {formatDateBdata(tooltipData.blockInfo.timestamp)}</div>
                        </div>
                    ) : (
                        ""
                    )
                }
                placement="top"
            >
                <div
                    style={{
                        top: tooltipData.y,
                        left: tooltipData.x,
                    }}
                    className={styles.blockMapToolTip}
                ></div>
            </Tooltip>
        </div>
    );
};

export default memo(BlockChainMap);
